@font-face {
  font-family: 'Futura PT';
  src: url('../fonts/FuturaPT-Light.eot');
  src: url('../fonts/FuturaPT-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/FuturaPT-Light.woff2') format('woff2'),
    url('../fonts/FuturaPT-Light.woff') format('woff'),
    url('../fonts/FuturaPT-Light.ttf') format('truetype'),
    url('../fonts/FuturaPT-Light.svg#FuturaPT-Light') format('svg');
  font-weight: 400;
  font-style: normal;
}

* {
  box-sizing: border-box;
  font-family: 'Futura PT', sans-serif;
}

html,
body {
  margin: 0;
  padding: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;

  background-color: #000;
  color: white;
}

.pinq {
  color: #f16c91;
}

.anim {
  transition: all 450ms ease-in-out;
}

#contact-button {
  color: white;
  text-decoration: none;
  transition: all ease-in-out 150ms;
}

#contact-button:hover {
  color: #f16c91;
}
